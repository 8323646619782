import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import FakeContent from "../components/support/FakeContent";
import Loading from "../components/Loading";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: FakeContent,
});
const Header = Loadable({
  loader: () => import("../components/support/Header"),
  loading: Loading,
});
const EnterpriseSupport = Loadable({
  loader: () => import("../components/support/EnterpriseSupport"),
  loading: Loading,
});
const OurSupportServices = Loadable({
  loader: () => import("../components/support/OurSupportServices"),
  loading: Loading,
});
const Platform = Loadable({
  loader: () => import("../components/SharedComponents/Platform"),
  loading: Loading,
});
const OperatingSystems = Loadable({
  loader: () => import("../components/SharedComponents/OperatingSystems"),
  loading: Loading,
});
const Database = Loadable({
  loader: () => import("../components/SharedComponents/Database"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading: Loading,
});

export default () => {
  return (
    <Layout>
      <SEO
        title="Enterprise Support | Datum Consulting NZ"
        description={
          "Our support services include phone support, " +
          "remote support, visit, network and security management, " +
          "end-used support, licencing, capacity planning, " +
          "configuration management, firewalls, antivirus, backup and recovery, " +
          "collaboration software and workflow automation."
        }
        keywords={[
          `aws`,
          `azure`,
          `IT support`,
          `engineer visit`,
          `software licencing`,
          `capacity planning`,
          `requirement engineering`,
          `firewalls`,
          `backup and recovery`,
          `collaboration software`,
          `aws`,
          `azure`,
          `cloud and hosting`,
          `workflow automation`,
          `google suits`,
          `office 365 suits`,
        ]}
        pathname="enterprise-support"
      />
      <Nav />
      <Header />
      <EnterpriseSupport />
      <OurSupportServices />
      <Platform />
      <OperatingSystems />
      <Database />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
