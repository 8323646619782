import React from "react";
import {SEO_TYPE} from "../../constant";
import SeoElement from "../SeoElement"

const data = [
    {
        type: SEO_TYPE.H1,
        heading: "Enterprise IT Support from Datum Consulting NZ"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "As enterprises become more and more networked, " +
            "IT resiliency and business continuity become more " +
            "and more important to the business survival. " +
            "Our support team can help you maintain, hot fix, enhance and " +
            "reduce the break down risks for your existing system."
    },
    {
        type: SEO_TYPE.H3,
        heading: "TELEPHONE & " +
            "REMOVE SUPPORT"
    },
    {
        type: SEO_TYPE.H3,
        heading: "ENGINEER VISIT"
    },
    {
        type: SEO_TYPE.H3,
        heading: "ASSESSMENT " +
            "REPORT"
    },
    {
        type: SEO_TYPE.H3,
        heading: "DEDICATED ACCOUNT " +
            "MANAGER"
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/contact/",
        title: "FREE YOUR TEAM FROM SUPPORT DUTY",
        label: "FREE YOUR TEAM FROM SUPPORT DUTY"
    },
    // services offering
    {
        type: SEO_TYPE.H2,
        heading: "Our enterprise support services"
    },
    // services offering: On demand support
    {
        type: SEO_TYPE.H3,
        heading: "On demand support"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "With a standard break/fix service contract, " +
            "your firm have to wait for " +
            "real business discontinuity " +
            "in order for your provider to fix. The time " +
            "in between is when you staff " +
            "suffer from productivity loss. Our team " +
            "can provide proactive maintenance and support at your request:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Telephone support", "Remote shared screen support", "Engineer visit"]
    },
    // services offering: Cloud and Hosting support
    {
        type: SEO_TYPE.H3,
        heading: "Cloud and Hosting support"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Our expert engineers can provide support for " +
            "IT infrastructure through " +
            "a wide range of Cloud platforms and on-premise cluster. " +
            "Our range " +
            "of services include:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Network & Security management",
            "Database management & Storage management"]
    },
    // services offering: End-user support
    {
        type: SEO_TYPE.H3,
        heading: "End-user support"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Our End-user solutions can cater the modern workforce and " +
            "delivering strong end-user experiences. Our services include:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Workstation and Mobile devices provisioning",
            "Remote access", "Collaboration and communication support"]
    },
    // services offering: Software & Operating Systems
    {
        type: SEO_TYPE.H3,
        heading: "Software & Operating Systems"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We can help with common day-to-day setup, " +
            "configuration, planning," +
            "backup and maintenance " +
            "of enterprise software to free you " +
            "from maintaining IT resource department:",
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Software licencing assessment",
            "Capacity assessment and planning",
            "Financial assessment and requirement planning",
            "Configuration assessment",
            "Firewalls and antivirus",
            "Backup & recovery solutions"
        ],
    },
    // services offering: Collaboration software suits
    {
        type: SEO_TYPE.H3,
        heading: "Collaboration software suits"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Trust us with layered and guarded enterprise IT setup on:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Office 365 suits", "Google suits",
            "Process and workflow automation"]
    },
    // list of Platform
    {
        type: SEO_TYPE.H2,
        heading: "Cloud platform"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Amazon Web Services (AWS)"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Microsoft Azure"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Google Cloud Platform (GCP)"
    },
    {
        type: SEO_TYPE.H3,
        heading: "IBM Cloud"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MuleSoft Anypoint"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redhat OpenShift"
    },
    // list of Operating system
    {
        type: SEO_TYPE.H2,
        heading: "Operating systems"
    },
    {
        type: SEO_TYPE.H3,
        heading: "CentOS"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redhat Linux"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Window Server"
    },
    // list of Database and Machine Learning
    {
        type: SEO_TYPE.H2,
        heading: "Database, Queues, Big Data and Machine Learning tools"
    },
    {
        type: SEO_TYPE.H3,
        heading: "ActiveMQ"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Airflow"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Kafka"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Spark"
    },
    {
        type: SEO_TYPE.H3,
        heading: "AWS DynamoDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Caffe 2"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cassandra"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cloudera"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Elasticsearch"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Hadoop"
    },
    {
        type: SEO_TYPE.H3,
        heading: "HazelCast"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MariaDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Memcached"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Metabase"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MongoDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MySQL"
    },
    {
        type: SEO_TYPE.H3,
        heading: "OracleDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "PowerBI"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Presto"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redis"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Segment"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SparkMLlib"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SQLServer"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Tableau"
    },
    {
        type: SEO_TYPE.H3,
        heading: "TensorFlow"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://linkedin.com/company/datumconsultingnz",
        title: "Linkedin",
        label: "Linkedin"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://twitter.com/datumhqNZ",
        title: "Twitter",
        label: "Twitter"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://www.facebook.com/DatumConsultingNZ",
        title: "Facebook",
        label: "Facebook"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://medium.com/datum-consulting-nz",
        title: "Medium",
        label: "Medium"
    }
];

const FakeContent = () => (
    <div
        style={{
            backgroundColor: "white",
            color: "transparent",
            height: "100vh",
            width: "100vw",
            position: "relative",
            top: "0",
            left: "0",
        }}
    >
        {data.map((element, index) => <SeoElement key={index} {...element}/>)}
    </div>
);

export default FakeContent;